<template>
  <h2 ref="previewText" class="tf-proc" :data-h="groupHeight">
    <slot></slot>
  </h2>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { DESKTOP_QUERY } from "./../../../constants/constants";
import { debounce } from "./../../../functions/general-functions";
import store from "../../store";
export const tfmixin = {
  store,
  created: function () {
    const _self = this;
    this.actualWidth = window.outerWidth;
    window.addEventListener(
      "resize",
      debounce(() => {
        if (this.actualWidth != window.outerWidth) {
          this.actualWidth = window.outerWidth;
          _self.resetTf();
          document.dispatchEvent(window.lrLoad);
        }
      }, 750)
    );
  },
  methods: {
    ...mapActions({
      resetTf: "tf/reset",
    }),
  },
};
export const TextFill = {
  store,
  props: ["maxFontSize", "groupName"],
  watch: {
    groupHeight: function (newVal, oldVal) {
      if (newVal && oldVal) {
        this.newFontSize = this.changeSize(newVal);
      }
    },
  },
  created: function () {
    const _self = this;
    this.processed = false;
    this.mq = window.matchMedia(DESKTOP_QUERY);
    document.addEventListener("lrload", () => {
      setTimeout(() => {
        _self.render();
      }, 200);
      setTimeout(() => {
        _self.render();
      }, 2000);
    });
  },
  mounted: function () {
    this.maxFontSize = this.maxFontSize ? this.maxFontSize : 60;
    this.windowSize = [1, this.maxFontSize];
  },
  computed: {
    ...mapGetters("tf", {
      heightByGroup: "heightByGroup",
      allHeights: "heights",
    }),
    groupHeight: function () {
      const gn = this.groupName ? this.groupName : "no-name";
      return this.heightByGroup(gn);
    },
  },
  methods: {
    ...mapActions({
      setH: "tf/setHeight",
    }),
    render: function () {
      this.newFontSize = Math.floor(this.maxFontSize / 2);
      this.windowSize = [1, this.maxFontSize];
      this.steps = 0;
      this.scaleText();
    },
    changeSize: function (fontSize) {
      const fsize = this.groupHeight
        ? Math.min(fontSize, this.groupHeight)
        : fontSize;

      this.$refs.previewText.style["font-size"] = fsize + "px";
      this.$refs.previewText.style["line-height"] = fsize + 4 + "px";
      return fsize;
    },
    scaleText: function () {
      this.steps++;
      this.newFontSize = this.changeSize(this.newFontSize);
      if (
        this.$refs.previewText.scrollHeight >
          this.$refs.previewText.offsetHeight ||
        this.$refs.previewText.scrollWidth > this.$refs.previewText.offsetWidth
      ) {
        this.windowSize[1] = this.newFontSize;
        this.newFontSize = Math.floor(
          this.windowSize.reduce(
            (all, one, _, src) => (all += one / src.length),
            0
          )
        );
      } else {
        if (this.windowSize[0] == this.newFontSize) {
          this.$refs.previewText.classList.remove("tf-proc");
          this.processed = true;
          const gn = this.groupName ? this.groupName : "no-name";
          this.setH({ groupName: gn, height: this.newFontSize });
          return;
        } else {
          this.windowSize[0] = this.newFontSize;
          this.newFontSize = Math.ceil(
            this.windowSize.reduce(
              (all, one, _, src) => (all += one / src.length),
              0
            )
          );
        }
      }
      this.scaleText();
    },
    resetScale: function () {
      this.$refs.previewText.style["font-size"] = null;
      this.$refs.previewText.style["line-height"] = null;
    },
  },
};

export default TextFill;
</script>
