<template>
  <div
    v-if="live"
    class="apertura-inside container-fluid mb-6"
    data-epica-module-name="Apertura En Vivo"
    data-mrf-recirculation="Apertura En Vivo"
  >
    <div
      class="container"
      v-if="live.feedTitle && live.feed && live.feed.length"
    >
      <div class="row en-vivo">
        <div class="col d-flex align-items-center mb-3">
          <div class="icon-live">
            <img
              class=""
              src="https://s.lalr.co/lr/images/icon-live-gif.gif"
              alt="Icono Live"
            /><span>EN VIVO</span>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div class="flex-grow-1">
          <div class="V_Video_Title">
            <div
              class="embed-responsive embed-responsive-16by9"
              v-html="live.embedVideo"
            ></div>
            <h2 class="tt">
              <a class="insideSect" v-html="live.title"></a>
            </h2>
          </div>
        </div>
        <div class="feedInside d-flex flex-column">
          <h2 class="title-feed-inside" v-html="live.feedTitle"></h2>
          <div class="wrap-feed d-flex flex-column flex-grow-1">
            <div class="feed-inside flex-grow-1">
              <div
                class="list-feed"
                v-for="(item, index) in live.feed"
                :key="index"
              >
                <img
                  v-if="item.image"
                  :src="item.image.url"
                  :alt="item.image.alt"
                />
                <span class="hour" v-html="item.time"></span>
                <span class="date" v-html="item.date"></span>
                <div v-html="item.content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container without-feed" v-else>
      <div class="row">
        <div class="col-8">
          <div
            class="embed-responsive embed-responsive-16by9"
            v-html="live.embedVideo"
          ></div>
        </div>
        <div class="col-4">
          <div class="d-flex flex-column title-inside">
            <span class="kicker">EN VIVO</span>
            <h2 class="flex-grow-1 tt">
              <a class="insideSect" v-html="live.title"></a>
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    loadLive: Boolean,
  },
  created: function () {
    this.init();
    if (this.loadLive) {
      this.getLive();
    }
  },
  updated: function () {
    if (this.live) {
      document.dispatchEvent(window.lrLoad);
    }
  },
  computed: {
    ...mapGetters("live", {
      live: "data",
    }),
  },
  methods: {
    ...mapActions("live", {
      init: "init",
      getLive: "getLive",
    }),
  },
};
</script>
