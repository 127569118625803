<template>
  <div
    class="I_Img_Title"
    v-if="post"
    :class="{ 'video-play-icon': post.format === 4 }"
  >
    <div class="wrapperMask">
      <a :href="post.postUrl">
        <span class="image-overlay"></span>
        <img-lr :image="post.principalImage"></img-lr>
      </a>
      <div class="contentMask">
        <a
          :href="post.postUrl"
          class="kicker"
          :class="post.section.className"
          v-html="post.header"
        ></a>
        <span class="date-news" v-html="postDate"></span>
        <h2>
          <a
            :href="post.postUrl"
            :class="post.section.className"
            v-html="post.title"
          ></a>
        </h2>
        <div class="autor" v-if="post.isAnalysis">
          <a :href="post.authorUrl" v-html="post.authorName"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ImgLr from "../../components/img-lr/ImgLr.vue";

moment.locale("es-co");
export default {
  components: { ImgLr },
  props: {
    post: Object,
    groupName: String,
  },
  computed: {
    postDate() {
      return moment(this.post.create).format("dd/MM/yyyy");
    },
  },
};
</script>
