<template>
  <div
    class="row news H_img_V_Title_Lead m-0"
    v-if="post"
    :class="{ 'video-play-icon': post.format === 4 }"
  >
    <div class="col-5 pl-0 pr-3">
      <a :href="post.postUrl" v-if="post.principalImage">
        <img-lr :image="post.principalImage"></img-lr>
      </a>
    </div>
    <div class="col-7 pl-3 pr-3">
      <a
        :href="post.postUrl"
        class="kicker"
        :class="post.section.className"
        v-html="post.header"
      ></a>
      <span class="date-news" v-html="postDate"></span>
      <h2 class="tt">
        <a
          :href="post.postUrl"
          :class="post.section.className"
          v-html="post.title"
        ></a>
      </h2>
      <p v-html="post.lead"></p>
      <div class="autor" v-if="post.isAnalysis">
        <a :href="post.authorUrl" v-html="post.authorName"></a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ImgLr from "../../components/img-lr/ImgLr.vue";

moment.locale("es-co");
export default {
  components: { ImgLr },
  props: {
    post: Object,
    groupName: String,
  },
};
</script>
