var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.post
    ? _c("div", { staticClass: "V_Title" }, [
        _c("a", {
          staticClass: "kicker",
          class: _vm.post.section.className,
          attrs: {
            href: _vm.post.isAnalysis ? _vm.post.authorUrl : _vm.post.postUrl,
          },
          domProps: {
            innerHTML: _vm._s(
              _vm.post.isAnalysis ? _vm.post.authorName : _vm.post.header
            ),
          },
        }),
        _vm._v(" "),
        _c("h2", { staticClass: "tt" }, [
          _c("a", {
            class: _vm.post.section.className,
            attrs: { href: _vm.post.postUrl },
            domProps: { innerHTML: _vm._s(_vm.post.title) },
          }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }