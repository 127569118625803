<template>
  <div class="V_Title" v-if="post">
    <a
      :href="post.isAnalysis ? post.authorUrl : post.postUrl"
      class="kicker"
      :class="post.section.className"
      v-html="post.isAnalysis ? post.authorName : post.header"
    ></a>
    <h2 class="tt">
      <a
        :href="post.postUrl"
        :class="post.section.className"
        v-html="post.title"
      ></a>
    </h2>
  </div>
</template>

<script>
import moment from "moment";

moment.locale("es-co");
export default {
  components: {},
  props: {
    post: Object,
    groupName: String,
  },
  computed: {
    postDate() {
      return moment(this.post.create).format("dd/MM/yyyy");
    },
  },
};
</script>
